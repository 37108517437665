import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostDetail from "../components/postDetail"
import { Newsletter } from "../components/newsletter"

class Post extends Component {
  render() {
    const { data } = this.props.data.post
    const title = data.article_title.text
    const tags = this.props.data.post.tags

    return (
      <Layout>
        <SEO
          title={`${title}`}
          keywords={[`safetylogz`, `blog`, tags.map(tag => `${tag}`)]}
          description={`${title} - Safetylogz Blog`}
          image={data.heading_image.url}
          imageAlt={`${title} - Safetylogz`}
        />
        <PostDetail
          title={title}
          image={data.heading_image.url}
          paragraph={data.paragraph.html}
          rawText={data.paragraph.text}
          author={data.author}
          tags={tags}
          authorImage={data.author_image.url}
          postDate={data.article_post_date}
        />
        <Newsletter />
      </Layout>
    )
  }
}

export default Post

export const postQuery = graphql`
  query Post($uid: String) {
    post: prismicBlogPost(uid: { eq: $uid }) {
      uid
      data {
        article_post_date(formatString: "Do MMMM, YYYY")
        article_title {
          text
        }
        heading_image {
          url
        }
        paragraph {
          html
          text
        }
        author
        author_image {
          url
        }
      }
      tags
    }
  }
`
