import React from "react"
import styled from "styled-components"

export const Newsletter = () => (
  <NewsletterWrapper>
    <script
      type="text/javascript"
      src="https://app.mailjet.com/statics/js/iframeResizer.min.js"
    ></script>
    <iframe
      title="safetylog subscription form widget"
      class="mj-w-res-iframe"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      src="https://app.mailjet.com/widget/iframe/5qSw/Ehv"
      width="100%"
    ></iframe>
  </NewsletterWrapper>
)

const NewsletterWrapper = styled.div`
  padding-bottom: 60px;
  margin-top: -30px;
  @media screen and (max-width: 414px) {
    padding-bottom: 110px;
  }
  iframe {
    min-height: 250px;
    display: block;
    margin: 0 auto;
    width: 773px;
    @media screen and (max-width: 414px) {
      width: 100%;
      min-height: 400px;
    }
  }
`
