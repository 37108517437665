import React from "react"
import styled from "styled-components"
import readingTime from "reading-time"

const PostDetail = props => {
  const readTime = readingTime(props.rawText)

  return (
    <>
      <PostHeader className="c-article-header">
        <h2>{props.title}</h2>
        <div className="tags__wrapper">
          {props.tags.map(tag => (
            <span key={tag}>{tag}</span>
          ))}
        </div>
        <p>{props.postDate}</p>
        <p>
          Written by <b>{props.author}</b> -{" "}
          <span className="read-time">{readTime.text}</span>
        </p>
        <img src={props.image} alt={`${props.title} - Safetylogz`} />
      </PostHeader>
      <PostWrapper>
        <div
          className="article-content"
          dangerouslySetInnerHTML={{ __html: props.paragraph }}
        />
      </PostWrapper>
    </>
  )
}

export default PostDetail

const PostHeader = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 1.45rem;
  .tags__wrapper {
    margin-bottom: 20px;
    display: flex;
    flex-flow: row wrap;
    span {
      background-color: #4685a4;
      color: white;
      padding: 10px 10px;
      margin-right: 15px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: bold;
    }
  }
  .read-time {
    font-size: 13px;
  }
`

const PostWrapper = styled.div`
  .article-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.45rem;

    .block-img {
      text-align: center;
      img {
        margin: 0 auto;
        max-width: 800px;
        width: auto;
        @media screen and (max-width: 414px) {
          width: 100%;
        }
        box-shadow: 3px 4px 21px -6px rgba(70, 133, 164, 0.7);
      }
    }
    h5 {
      font-size: 24px;
      color: #4685a4;
      text-align: center;
    }
    h4 {
      font-size: 24px;
    }
    margin-bottom: 30px;
  }
`
